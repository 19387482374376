<template lang="pug">
#ModalWindow.Modal
  .modal-container
    h4.modal-container__title {{ secondStep ? 'Выставите поправку в пределах 50% :' : 'Выберите источник:' }}
    vue-scroll.modal-container__inner(v-if="!secondStep")
      .modal-item(v-for="(item, name) in filterUndefinedValues")
        .modal-item-title(@click="selectSource(name, item)")
          .modal-item-title__text {{ findTitle(name) }}
          .modal-item-title__date {{ item !== null ? `${Math.round(item)} ${currency.toUpperCase()}` : 'NOT_DEFINED' }}
          i.modal-item-title__icon.el-icon-arrow-right
    vue-scroll.modal-container__inner(v-if="secondStep")
      .offset-enter
        input.offset-enter__input(
          type='number',
          v-model.number="selectedPrice.offset"
          v-validate="'required|decimal:2|max:50|min_value:-50'",
          :class="{'offset-enter--error':  errors.has('offset') }",
          placeholder='Введите',
          name='offset',
          max="50",
          min="-50",
          step="0.01",
          autocomplete="off",
        )
        .offset-enter__text(:class="{'offset-enter--error':  errors.has('offset')}",)
          | %
      p.auto-price {{ actualPrice }} {{currency}}
    .modal-buttons(v-if="secondStep")
      .modal-buttons__item.modal-buttons__item--accept(v-if="!errors.has('offset')", @click="turnOnAutoPrice") Подтвердить
      .modal-buttons__item.modal-buttons__item--error(v-else) Подтвердить
    .modal-buttons
      .modal-buttons__item.modal-buttons__item--cancel(@click="closeAutoPrice") Отмена
</template>

<script>
import utils from '../../mixins/utils';
import {REQUEST_PRICE} from '../../../store/actions/ad';
import { mapGetters } from 'vuex';
import InputRangeOffset from './InputRandeOffset';

export default {
  components: {
    InputRangeOffset,
  },
  mixins: [utils],
  props: {
    currency: {
      type: String,
      default: 'rub',
    },
    cryptoCurrency: {
      type: String,
      default: 'btc',
    },
  },
  data() {
    return {
      sources: [
        {
          alias: 'blockchaininfo',
          title: 'Blockchain.info (ticker)',
        },
        {
          alias: 'cryptonator',
          title: 'Cryptonator',
        },
        {
          alias: 'cmc',
          title: 'CoinMarketCap',
        },
        {
          alias: 'blockchain',
          title: 'Blockchain.info (chart)',
        },
        {
          alias: 'binance',
          title: 'Binance',
        },
        {
          alias: 'garantex',
          title: 'Garantex.io',
        },
      ],
      selectedPrice: {
        source: '',
        price: 0,
        offset: 0,
      },
      secondStep: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAutoPrice',
    ]),
    filterUndefinedValues() {
      return this.getAutoPrice.sources ? Object.fromEntries(Object.entries(this.getAutoPrice.sources).filter( item => item[1] !== null && item[1] !== 0 && item[1] !== '')) : {};
    },
    actualPrice() {
      return parseFloat(this.selectedPrice.offset === 0 ? Math.round(this.selectedPrice.price) : Math.round(this.offsetPrice));
    },
    offsetPrice() {
      return this.selectedPrice.offset > 0 ? (this. selectedPrice.price * (this.selectedPrice.offset / 100)) + this.selectedPrice.price : this.selectedPrice.price + (this. selectedPrice.price * (this.selectedPrice.offset / 100));
    },
    AutoPriceSources() {
      return Object.entries(this.getAutoPrice.sources);
    },
  },
  created() {
    this.requestPrice();
  },
  methods: {
    findTitle(name) {
      let title = this.sources.find((val) => {
        return val.alias === name;
      })
      return title === undefined ? 'NOT_DEFINED' : title.title;
    },
    closeAutoPrice() {
      this.$emit('closeAutoPrice');
    },
    turnOnAutoPrice() {
      this.$emit('turnOnAutoPrice', true, this.selectedPrice.offset, this.selectedPrice.source, this.actualPrice);
    },
    selectSource(source, item) {
      this.selectedPrice.source = source;
      this.selectedPrice.price = Math.round(item);
      this.secondStep = true;
    },
    requestPrice() {
      this.$store.dispatch(REQUEST_PRICE, { crypto: this.cryptoCurrency, fiat: this.currency });
    },
  }

}
</script>

<style lang="scss" scoped>

.offset-enter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #7486B3;

  &--error {
    border-color: orange !important;
  }

  &__input {
    width: 35%;
    background: #F7F8FA;
    border: 1px solid #E7EEFF;
    font-size: 16px;
    border-radius: 10px 0 0 10px;
    border-right: 0;
    height: 50px;
    padding-left: 20px;
    padding-right: 10px;
    outline: none;

    &:focus {
      background: #fff;
    }
  }

  &__text {
    width: 15%;
    background: #F7F8FA;
    border: 1px solid #E7EEFF;
    font-size: 14px;
    text-align: center;
    padding-top: 16px;
    height: 50px;
    border-radius: 0 10px 10px 0;
  }
}

.auto-price {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  padding-top: 10px;
  margin: 0;
}

#ModalWindow {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background: rgba(0, 0, 0, 0.7);
}

.modal-container {
  display: flex;
  background: #fff;
  width: 100%;
  max-width: 450px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 20px;
  max-height: 75%;
  overflow: hidden;
  flex-direction: column;

  &__inner {
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.modal-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 20px;

  &-title {
    width: 100%;
    padding: 5px 0px 5px 0px;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;


    &:hover {
      cursor: pointer;
      background: rgba(238, 238, 238, 0.466);
    }

    &__text {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
    }

    &__date {
      color: #fff;
      background: #2464EB;
      padding: 2px 15px;
      font-size: 12px;
      border-radius: 25px;
      white-space: nowrap;
    }

    &__icon {
      margin-left: 15px;
      color: #000;
      padding: 3px;
    }
  }

  &__text {
    background: $blue100;
    padding: 15px;
    width: 100%;
    border: 1px solid $blue300;
    border-top: 0;
    font-size: 14px;
    white-space: pre-wrap;
    word-break: break-all;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.modal-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 390px;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.5;

    &--accept {
      background: #2464EB;
      color: #fff;

      &:hover {
        cursor: pointer;
        transition: 0.2s;
        background: #1543A4;
      }
    }

    &--error {
      opacity: 0.8;
      background: #2464EB;
      color: #fff;
    }

    &--cancel {
      color: $blue600;
      border: 1px solid $blue600;
      &:hover {
        cursor: pointer;
        background-color: $blue100;
      }
    }
  }
}
</style>
