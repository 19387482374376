<template lang="pug">
.requsites-adder(v-if='is_sale')
  .requsites-adder__add-button(
    v-if="!requisiteAdd && editDialogIds.length === 0"
    @click="requisiteAdd = true",
  )
    i.btn-deposit__plus-icon
    span Добавить реквизит
  .requsites-adder__header(v-else-if="editDialogIds.length > 0")
    span Изменение реквизитов
  .requsites-adder__header(v-else)
    span Добавление реквизита
  .requsites-adder__message(v-if="!requisiteAdd && requisiteList.length === 0")
    span Ни одного реквизита еще не добавлено...
  .requsites-create(v-if="requisiteAdd")
    .requsites-create-main
      .create-types
        .create-types__item(
          v-for="type in getRequisiteTypes",
          :class="{'create-types__item--active': selectedType.id === type.id}"
          @click="selectedType = type"
        )
          span {{ type.title }}
      RequisitesAdderInput(
        :country_id="country_id",
        :selectedType="selectedType",
        :ad_banks="ad_banks"
        :bank_options="bank_options"
        @closeAddRequisites="closeAddRequisites",
        @addRequisite="addRequisite",
      )
  draggable.requsites-list__item(
    v-model="requisiteList"
    ghost-class="ghost"
    handle=".handle"
    :disabled="random"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    @change="updateAutoSelectStatus($event.moved.element)"
  )
    .requsites-list(v-for="(item, index) in requisiteList" v-if="!item.isDeleted")
      .requsites-list-main(v-if="!isShowDialogForIndex(index)")
        .list-item
          i(v-if="!random && isAutoSelected !== 0" class="el-icon-s-grid handle")
          .list-item__title {{ getItemTitle(item.type) }}
          .list-item__requisite {{ item.requisite }}
          .requsites-list-buttons(v-if="!requisiteAdd")
            i.el-icon-edit.control-button(@click="showEditDialog(index)")
            i.el-icon-delete.control-button(@click="deleteRequisite(index)")
        .list-item(v-if="item.bank_id" :class="{ no_bank: !hasBankInAd(item.bank_id) }")
          .list-item__empty(v-if="!random && isAutoSelected !== 0")
          .list-item__title Банк
          .list-item__requisite(:class="{ 'max-input-text70':!requisiteAdd }")
            template(v-if="!hasBankInAd(item.bank_id)")
              el-tooltip(
                class="item"
                effect="dark"
                content="Этого банка нет в объявлении"
                placement="top"
              )
                div {{ getBankTitle(item.bank_id) }}
            template(v-else)
              div {{ getBankTitle(item.bank_id) }}
        .list-item(v-if="item.max > 0")
          el-tooltip(v-if='!random && isAutoSelected !== 0' effect="dark" placement="top" content='Приоритет')
            .list-item__priority
              i.el-icon-info(v-if='item.priority')
              span(v-if='item.priority') {{ item.priority }}
          .list-item__title(:class="{'max-input-title': !random && isAutoSelected !== 0 }") Лимит
          .list-item__requisite(:class="{'max-input-text': !requisiteAdd }") {{item.turnover + '/' + item.max}}
          .requsites-list-buttons(v-if="!requisiteAdd")
            i.el-icon-brush.control-button(title="Сбросить оборот" @click="onReset(item)")
        .list-auto
          .list-auto__switch
            el-switch(
              v-model="item.auto_select",
              active-color="#2464EB",
              @change="updateAutoSelectStatus(item)",
              :disabled="!item.auto_select && isAutoSelected >= 50",
              inactive-color="#ff4949",
            )
          .list-auto__text
            p - выдавать реквизит автоматически
        DealsLimit(
          v-if="item.auto_select"
          :requisite="item"
          :is-updating="isRequisiteUpdating",
          @update:requisite="updateRequisite"
        )
      RequisitesAdderInput(
        v-else,
        :country_id="country_id",
        :requisiteId="item.id"
        :req_bank_id="item.bank_id"
        :selectedType="getSelectedTypeByAlias(item.type)",
        :editRequisite="item.requisite",
        :isEdit="true",
        :limit="Number(item.max)"
        :editRequisiteIndex="index",
        :ad_banks="ad_banks"
        :bank_options="bank_options",
        @closeEditDialog="closeEditDialog",
        @updateRequisite="updateRequisite"
      )
  .list-auto.random-toggle(v-if="requisiteList.length > 0 && isAutoSelected > 0")
    .list-auto__switch
      el-switch(
        :value="random"
        :disabled="isLoading"
        @change="onRandom"
        active-color="#2464EB"
        class="random-btn"
      )
    .list-auto__text.list-text-item
      p - Выдавать реквизиты случайным образом

</template>

<script>
import {mapGetters} from 'vuex';
import {REQUISITE_TYPES} from '@/store/actions/ad';
import RequisitesAdderInput from "./RequisitesAdderInput";
import draggable from 'vuedraggable'
import {AD, REQUISITE_ADD, REQUISITE_DELETE, REQUISITE_UPDATE, SET_STRATEGY, RESET_TURNOVER} from "@/store/actions/ad";
import DealsLimit from "./DealsLimit.vue";
import {isArray} from "lodash/lang";

export default {
  components: {DealsLimit, RequisitesAdderInput, draggable},
  props: {
    country_id: {
      type: Number,
      default: 1,
    },
    payment_requisites: {
      type: Array,
      default() {
        return [];
      },
    },
    ad_id: {
      type: Number,
      default: null,
    },
    is_sale: {
      type: Boolean,
      default: false
    },
    payment_system_id: {
      type: Number,
      default: null
    },
    ad_banks: {
      type: Array,
      default() {
        return [];
      },
    },
    bank_options: {
      type: Array,
      default() {
        return [];
      },
    },
    strategy: String
  },
  data() {
    return {
      requisiteAdd: false,
      requisiteList: [],
      editDialogVisible: false,
      editDialogIds: [],
      selectedType: {
        id: 2,
        alias: 'card',
        title: 'Карта',
      },
      drag: false
    }
  },
  computed: {
    ...mapGetters([
      'getRequisiteTypes',
      'loading'
    ]),
    isAutoSelected() {
      let active = this.requisiteList.filter((item) => {
        return item.auto_select === true;
      });
      return active.length;
    },
    random() {
      return this.strategy === 'random'
    },
    dragOptions() {
      return {
        animation: 300,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    isLoading() {
      return this.loading(SET_STRATEGY) === 'loading'
    },
    isRequisiteUpdating() {
      return this.loading(REQUISITE_UPDATE) === 'loading'
    }
  },
  watch: {
    payment_requisites(val) {
      this.requisiteList = this.getFormattedRequisites(val);
    }
  },
  created() {
    this.$store.dispatch(REQUISITE_TYPES);
  },
  mounted() {
    this.requisiteList = this.getFormattedRequisites(this.payment_requisites);
  },
  methods: {
    getItemTitle(alias) {
      let get_alias = _.isObject(alias) ? alias.alias : alias;
      const type = this.getRequisiteTypes.find(type => type.alias === get_alias);
      return type && type.title ? type.title : null;
    },
    getFormattedRequisites(requisites) {
      const result = [];
      requisites.forEach((requisite) => {
        result.push({
          type: requisite.type.alias || requisite.type,
          requisite: requisite.requisite,
          auto_select: requisite.auto_select,
          id: requisite.id,
          isEdited: requisite.isEdited,
          isDeleted: requisite.isDeleted,
          ad_id: requisite.ad_id,
          max: requisite.max,
          priority: requisite.priority,
          turnover: requisite.turnover,
          bank_id: requisite.bank ? requisite.bank.id : null,
          deals_limits: requisite.deals_limits ?? null,
          deals_left: requisite.deals_left ?? null,
          deals_quantity: requisite.deals_quantity ?? null,
          deals_limit_reset_at: requisite.deals_limit_reset_at ?? null,
        });
      });
      return result.sort((a, b) => b.priority - a.priority);
    },
    async addRequisite(requisite) {
      if (this.ad_id) {
        requisite.isEdited = true;
        requisite.ad_id = this.ad_id;
      }
      requisite.payment_system_id = this.payment_system_id;
      const data = {
        adId: requisite.ad_id,
        data: requisite
      };
      try {
        await this.$store.dispatch(REQUISITE_ADD, data);
        await this.$store.dispatch(AD, { id: this.$route.params.id, req: true });
      } catch (e) {
        console.error('Error add requisite', e);
        this.$notify({
          message: 'Не удалось добавить реквизит',
          type: 'error'
        });
      }
    },
    async updateRequisite(requisite) {
      const index = Number.isInteger(requisite.index) ? requisite.index : this.requisiteList.findIndex(i => i.id === requisite.id);
      const currentRequisite = this.requisiteList[index];
      if (currentRequisite) {
        currentRequisite.requisite = requisite.requisite;
        currentRequisite.max = requisite.max;
        if (currentRequisite.id) {
          currentRequisite.isEdited = true;
        }
        currentRequisite.payment_system_id = this.payment_system_id;
        if (requisite.bank_id) {
          currentRequisite.bank_id = requisite.bank_id
        }
        let data = {
          requisite: currentRequisite.id,
          data: currentRequisite
        };
        try {
          const res = await this.$store.dispatch(REQUISITE_UPDATE, data);
          res.data && this.$message({
            message: 'Реквизит успешно обновлен',
            type: 'success',
          });
          res.data.type = currentRequisite.type
          res.data.bank_id = res.data.bank ? res.data.bank.id : null
          this.requisiteList[index] = res.data;
        } catch (e) {
          console.error('Error update requisite', e);
          this.$notify({
            message: 'Не удалось обновить реквизит',
            type: 'error'
          });
        }
      }
      this.editDialogIds.splice(this.editDialogIds.indexOf(index), 1);
    },
    deleteRequisite(index) {
      const requisite = this.requisiteList[index];
      this.$confirm('Вы действительно хотите удалить реквизит?', 'Внимание!', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Не удалять',
        confirmButtonClass: 'button-bg--red',
        type: 'warning'
      }).then(async () => {
        if (requisite.id) {
          requisite.isDeleted = true;
          requisite.auto_select = false;
          await this.$store.dispatch(REQUISITE_DELETE, {requisite: requisite.id});
          await this.$store.dispatch(AD, { id: this.$route.params.id, req: true });
        }
      });
    },
    showEditDialog(index) {
      this.editDialogIds.push(index);
    },
    closeEditDialog(index) {
      this.editDialogIds.splice(this.editDialogIds.indexOf(index), 1);
    },
    closeAddRequisites() {
      this.requisiteAdd = false;
    },
    getSelectedTypeByAlias(alias) {
      return this.getRequisiteTypes.find(type => {
        return type.alias === alias;
      });
    },
    getBankTitle(bank_id) {
      const bank = this.bank_options.find((bank) => bank.value === bank_id)
      return bank ? bank.label : 'Не известно';
    },
    hasBankInAd(bank_id) {
      return this.ad_banks.includes(bank_id);
    },
    isShowDialogForIndex(index) {
      return this.editDialogIds.indexOf(index) !== -1;
    },
    updateAutoSelectStatus(item) {
      if (this.ad_id) {
        item.isEdited = true;
      }
      let index = this.requisiteList.filter(i => i.auto_select).length;
      this.requisiteList.forEach(elem => { // TODO перепроверить или удалить
        // const oldPriority = elem.priority;
        elem.isEdited = true;
        if (elem.auto_select) {
          elem.priority = index;
          index--;
        } else {
          elem.priority = null
        }
        let data = {
          requisite: elem.id,
          data: elem
        };
        // if (oldPriority !== elem.priority && !elem.isDeleted) { // TODO перепроверить или удалить
        this.$store.dispatch(REQUISITE_UPDATE, data);
        // }
      });
    },
    getHTMLFormattedText(requisite) {
      return requisite.replace(new RegExp('\n', 'g'), '<br/>');
    },
    onRandom() {
      this.requisiteList.forEach(item => {
        item.priority = null;
      });
      let data = {
        id: this.ad_id,
        requisite_strategy: this.random ? "priority" : "random"
      };
      this.$store.dispatch(SET_STRATEGY, {id: this.ad_id, data});
    },
    async onReset(requisite) {
      try {
        const { data } = await this.$store.dispatch(RESET_TURNOVER, {id: requisite.id});
        const idx = this.requisiteList.findIndex(i => i.id === requisite.id)
        Object.keys(data).forEach(key => {
          this.requisiteList[idx][key] = data[key]
        })
        this.requisiteList[idx].type = data.type.alias
        this.$notify({
          message: 'Оборот обнулён',
          type: 'success'
        });
        await this.updateAutoSelectStatus(requisite)
      } catch (e) {
        this.$notify({
          message: 'Не удалось обнулить оборот',
          type: 'warning'
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.requsites-adder {
  display: flex;
  flex-direction: column;
  width: 500px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 26px;
  margin-bottom: 15px;
  min-height: 100px;
  padding-bottom: 10px;

  &__add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #2464EB;

    span {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      background: $blue800;
      cursor: pointer;
      transition: 0.3s;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #2464EB;

    span {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;

    span {
      color: #000;
      font-size: 16px;
    }
  }
}

.requsites-create {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 5px;

  &:last-of-type {
    border-bottom: 0;
  }

  &-main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.create-types {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue100;
    border: 1px solid $blue300;
    border-radius: 6px;
    padding: 0 15px;
    font-size: 14px;
    margin-right: 15px;

    &--active {
      background: $blue300;
    }

    &:hover {
      cursor: pointer;
      transition: 0.3s;
      background: $blue300;
    }
  }
}

.requsites-list__item::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
  cursor: pointer;
}

.requsites-list__item::-webkit-scrollbar-track {
  transition: opacity 0.5s ease 0s;
  cursor: pointer;
}

.requsites-list__item::-webkit-scrollbar-thumb {
  background: rgb(188, 201, 239);
  border-radius: 8px;
}

.requsites-list__item {
  overflow-y: scroll;
  max-height: 450px;

  .requsites-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-top: 1px solid #ddd;

    &:last-of-type {
      border-bottom: 0;
    }

    &-main {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 10px;

      .control-button {
        font-size: 20px;
        padding: 0 2.5px;

        &:hover {
          cursor: pointer;
          transition: 0.3s;
        }

        &.el-icon-edit {
          color: #2464EB;
        }

        &.el-icon-delete {
          color: #FF2D55;
        }
        &.el-icon-brush {
          color: #7c7c7c;
        }
      }
    }
  }
}


.list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue300;
    border: 1px solid $blue300;
    border-radius: 6px;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 14px;
    padding: 0 15px;
    width: 25%;
    align-self: stretch;
  }

  &__empty {
    width: 35px;
  }

  &__priority {
    display: flex;
    margin: 0 auto;
    width: 31px;
    font-size: 14px;
  }

  .el-icon-info {
    margin: auto 3px auto 0;
    font-size: 13px;
  }

  &__requisite {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue100;
    border: 1px solid $blue300;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 14px;
    padding: 5px 15px;
    min-height: 34px;
    width: 75%;
    color: $blue600;
    word-break: break-word;

    &.other-requisite {
      justify-content: flex-start;
    }
  }

  &.no_bank .list-item__title,
  &.no_bank .list-item__requisite
  {
    border-color: $red;
  }
}

.random-toggle {
  margin-top: 2px;
  padding-top: 8px;
  border-top: 1px solid #ddd;
  background: #ffffff;
  -webkit-box-shadow: 0 2px 14px rgba(0, 0, 0, 0.148211);
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.148211);
}

.list-auto {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  &__text {
    padding-left: 10px;

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.random-btn {
  margin-left: 10px;
}

.max-input-title {
  margin-left: 5px;
}

.max-input-text {
  margin-right: 25px;
}

.max-input-text70 {
  margin-right: 70px;
}

.ghost {
  opacity: 0.5;
  background: #7fcc8c;
}

.handle {
  float: left;
  width: 36px;
  color: #8a8a8a;
  cursor: move;
}
</style>
