<template lang="pug">
  .create-line__item
    p.create-line__item-title {{ title }}
    .select-box(v-if="disableSelect === false")
      .select-box__current(
        :tabindex='tabIndex',
        @blur="isSelectOpen = false",
        ref="select",
        v-if="isAvailable"
      )
        .select-box__value(v-for="item in items", :key="item.value")
          input.select-box__input(
            type='radio',
            :name="name",
            :checked="item.value === value ? 'checked' : ''",
            :id="itemHtmlId(item.value)",
            :value="item.value",
            @input="$emit('input', $event.target.value)"
          )
          .select-box__input-text(@click="checkSelectOpen()")
            span.select-box__block-icon(v-if="defaultIcon !== ''", :style="itemIcon(item)")
            p.create-line__item-select--text {{item.label}}
        img.select-box__icon(src='/img/arrow-down-gray.svg', alt='Arrow Icon', aria-hidden='true', @click="checkSelectOpen()")
      .select-box__current.create-line__item-select(v-else)
        .select-box__input-text.no-available__title Нет доступных
      ul.select-box__list
        li(v-for="item in items", :key="item.value")
          label.select-box__option(:for='itemHtmlId(item.value)')
            span.select-box__block-icon(v-if="defaultIcon !== ''", :style="itemIcon(item)")
            | {{item.label}}

    .select-box(v-if="disableSelect === true")
      .select-box__current.select-box__disabled(:tabindex='tabIndex')
        .select-box__value(v-for="item in items", :key="item.value")
          input.select-box__input(
            type='radio',
            :name="name",
            :checked="item.value === value ? 'checked' : ''",
            :id="itemHtmlId(item.value)",
            :value="item.value",
            @input="$emit('input', $event.target.value)"
          )
          .select-box__input-text
            span.select-box__block-icon(v-if="defaultIcon !== ''", :style="itemIcon(item)")
            p.create-line__item-select--text {{item.label}}
</template>

<script>
export default {
  name: 'SelectBoxAd',
  props: {
    disableSelect: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default: '',
    },
    defaultIcon: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    styleWidth: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSelectOpen: false,
      isAvailable: true,
    }
  },
  watch: {
    items(newItems) {
      this.checkAvailable(newItems);
    },
  },
  mounted() {
    this.checkAvailable(this.items);
  },
  methods: {
    checkAvailable(items) {
      this.isAvailable = items.length > 0;
    },
    itemHtmlId(id) {
      return this.name + '-' + id;
    },
    itemIcon(item) {
      return `background: url("${item.icon
        ? item.icon
        : this.defaultIcon}") no-repeat center; background-size: 80% auto;`;
    },
    checkSelectOpen() {
      this.isSelectOpen = !this.isSelectOpen;
      if (!this.isSelectOpen) {
        this.$refs.select.blur();
      }
    },
  },
};
</script>

<style scoped>
  .select-box-desktop {
    margin-right: 20px;
  }
  .select-box__input-text.no-available__title {
    display: flex;
    align-items: center;
    border: 1px solid #f10d0d;
  }
</style>
