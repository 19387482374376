<template lang="pug">
.create-offer--step
  AutoPrice(
    v-if="showAutoPriceSettings",
    :currency="labels.currency.toLowerCase()",
    :cryptoCurrency="labels.crypto_currency.toLowerCase()",
    @closeAutoPrice="showAutoPriceSettings = false",
    @turnOnAutoPrice="turnOnAutoPrice",
  )
  .create-offer__content
    .create-line.create-line--center(v-if="isEdit === false")
      .typeButton_sort
        span.typeButton_sort-item(
          :class="{'typeButton_sort-item--active': form.is_sale === true, 'disabled' : isZeroSellerBalance}",
          @click="changeType(true)",
          :title="zeroSellerBalanceTooltip"
        )
          | Продажа

        span.typeButton_sort-item(
          :class="{'typeButton_sort-item--active': form.is_sale === false}",,
          @click="changeType(false)",
        )
          | Покупка

    .create-line.create-line--left(v-else)
      h2.type-title Редактирование объявления о {{ form.is_sale === true ? 'продаже' : 'покупке' }}
    .create-line
      select-box-ad(
        tabIndex="1",
        :items="cryptoOptions",
        name="CryptoSelect",
        v-model.number="form.crypto_currency_id",
        :title="'Криптовалюта'",
        :disableSelect="isEdit === true"
      )
      select-box-ad(
        tabIndex="2",
        :items="countryOptions",
        name="CountrySelect",
        v-model.number="form.country_id",
        :title="'Страна'",
        :disableSelect="isEdit === true"
      )
      select-box-ad(
        tabIndex="3",
        :items="currencyOptions",
        name="FiatSelect",
        v-model.number="form.currency_id",
        @change="getTicker()",
        :title="'Валюта'",
        :disableSelect="isEdit === true"
      )
      select-box-ad(
        tabIndex="4",
        :items="timeOptions",
        name="timeSelect",
        v-model.number="form.time",
        :title="'Окно оплаты'",
        :disableSelect="timeOptions.length <= 1"
      )
    .create-line
      select-box-ad(
        tabIndex="5",
        :items="paymentSystemOptions",
        :defaultIcon="defaultPaymentIcon",
        name="paymentSelect",
        ref="paymentSelect",
        v-model.number="form.payment_system_id"
        :title="'Метод оплаты'",
        :disableSelect="isEdit === true"
      )
      .create-line__item
        .price-box
          p.create-line__item-title Цена
          i.el-icon-sort.price-box__icon(
            title="Режим демпинга",
            @click="enableBot",
            :class="{'price-box__icon--active': form.bot && form.bot.enable }",
          )
          i.el-icon-coin.price-box__icon(
            title="Автоматическая установка цены",
            @click="enableAutoPrice",
            :class="{'price-box__icon--active': form.auto_price && form.auto_price.enabled }",
          )
        .create-input(:title="hasMinMaxPriceError ? hasMinMaxPriceError : errors.first('price')")
          input.create-input__item(
            type='text',
            v-model.number='form.price',
            v-validate="'required|decimal:2|min_value:1|max:12'",
            :class="{'create-input__item--error': errors.has('price') || hasMinMaxPriceError }",
            name='price',
            :disabled="form.auto_price && form.auto_price.enabled",
            placeholder='Введите',
            maxlength='12',
            @input="inputChange('price')"
          )
          .create-input__text(:class="{'create-input__text--error': errors.has('price') || hasMinMaxPriceError }",)
            | {{ labels.currency }}
      .create-line__item
        p.create-line__item-title Минимальная сумма сделки
        .create-input(:title="errors.first('min') ? errors.first('min') : isLimitError === true ? 'Минимальный лимит не может быть больше или равен максимальному' : ''")
          input.create-input__item(
            type='text',
            v-model.trim='form.min',
            v-validate="'required|decimal:2|max:12|min_value:1'",
            :class="{'create-input__item--error': errors.has('min') || isLimit() }",
            placeholder='Введите',
            name='min',
            maxlength='12',
            @input="inputChange('min')"
          )
          .create-input__text(:class="{'create-input__text--error': errors.has('min') || isLimit() }",)
            | {{ labels.currency }}
      .create-line__item
        p.create-line__item-title Максимальная сумма сделки
        .create-input(:title="errors.first('max') ? errors.first('max') : hasMaxError ? hasMaxError : ''")
          input.create-input__item(
            type='text',
            v-model.trim='form.max',
            v-validate="'required|decimal:2|max:15|min_value:1'",
            :class="{'create-input__item--error': errors.has('max') || isLimit() || hasMaxError  }",
            placeholder='Введите',
            name='max',
            maxlength='15',
            @input="inputChange('max')",
          )
          .create-input__text(:class="{'create-input__text--error': errors.has('max') || isLimit() || hasMaxError  }",)
            | {{ labels.currency }}
  .create-line(v-show='showBanks')
    multiselect.banks-select(
      v-model="bankSelected",
      :options="bankOptions",
      placeholder="Выберите",
      :searchable="true",
      label="label",
      name="bankSelect",
      track-by="value",
      :close-on-select="false",
      :clear-on-select="false",
      :multiple="true",
      deselectGroupLabel="Отменить выбор группы"
      deselectLabel="Удалить"
      selectGroupLabel="Выбрать группу"
      selectLabel="Выбрать"
      selectedLabel="Выбран"
    )
  .create-extra-options__link(@click="extraOptionsAction" :class="{'active': extraOptions === true}") Дополнительные параметры
  .create-extra-options(v-if="extraOptions === true")
    #content-1.create-extra-options__content
      .create-line
        .create-line__item
          p.create-line__item-title Сделок не менее
          .create-input(:title="errors.first('min_deal_finished_count') ? errors.first('min_deal_finished_count') : ''")
            input.create-input__item(
              type='text',
              v-model.trim='form.min_deal_finished_count',
              v-validate="'numeric|min_value:0|max_value:1000000|max:7'",
              placeholder='Введите',
              :class="{'create-input__item--error': errors.has('min_deal_finished_count') }",
              name='min_deal_finished_count',
              maxlength='7',
            )
            .create-input__text(:class="{'create-input__text--error': errors.has('min_deal_finished_count') }",)
              | шт
        .create-line__item
          p.create-line__item-title Минимальный рейтинг
          .create-input(:title="errors.first('review_rate') ? errors.first('review_rate') : ''")
            input.create-input__item(
              type='text',
              v-model.trim='form.review_rate',
              v-validate="'numeric|min_value:0|max_value:99|max:2'",
              :class="{'create-input__item--error': errors.has('review_rate') }",
              placeholder='Введите',
              name='review_rate',
              maxlength='2',
            )
            .create-input__text(:class="{'create-input__text--error': errors.has('review_rate') }",)
              | %
        .create-line__item
          p.create-line__item-title Процент отмененных сделок
          .create-input(:title="errors.first('cancel-deal') ? errors.first('cancel-deal') : ''")
            input.create-input__item(
              type='text',
              v-model.trim='form.deal_cancellation_max_percent',
              v-validate="'numeric|min_value:0|max_value:99|max:2'",
              :class="{'create-input__item--error': errors.has('cancel-deal') }",
              placeholder='Введите',
              name='cancel-deal',
              maxlength='2'
            )
            .create-input__text(:class="{'create-input__text--error': errors.has('cancel-deal') }",)
              | %
        .create-line__item
          p.create-line__item-title Минимальный оборот
          .create-input(:title="errors.first('turnover') ? errors.first('turnover') : ''")
            input.create-input__item(
              type='text',
              v-model.trim='form.turnover',
              v-validate="'numeric|decimal:8|min_value:0|max:12'",
              :class="{'create-input__item--error': errors.has('turnover') }",
              placeholder='Введите',
              name='turnover',
              maxlength='12',
              @input="inputChange('turnover')"
            )
            .create-input__text(:class="{'create-input__text--error': errors.has('turnover') }",)
              | {{ labels.crypto_currency }}
    .create-line.create-line--left
      .create-line_item
        .create-line__item-checkbox
          input#check1.check(type='checkbox', name='check', v-model='form.liquidity_required')
          label.check-label(for='check1') Включить ликвидность
      .create-line_item(v-if="false")
        .create-line__item-checkbox
          input#check2.check(type='checkbox', name='check', v-model='form.email_confirm_required')
          label.check-label(for='check2') Подтвержденный E-mail
      .create-line_item
        .create-line__item-checkbox
          input#check3.check(type='checkbox', name='check', v-model='form.tor_denied')
          label.check-label(for='check3') Не осуществлять операции с TOR-пользователями
  .create-offer__content
    .create-line.create-line--between.create-line--nopadding.create-line--bottom(:style="{justifyContent: isEdit ? 'space-between' : 'flex-end'}")
      RequisitesAdder(
        v-if="isEdit"
        :country_id="form.country_id",
        :payment_requisites="form.requisites",
        :ad_id="form.id"
        :is_sale="form.is_sale"
        :strategy="form.requisite_strategy"
        :payment_system_id="form.payment_system_id"
        :ad_banks="bankSelectedForRequisites"
        :bank_options="bankOptions"
      )
      template(v-else)
        span.requisites-adder-text(v-if='form.is_sale') Для добавление реквизита необходимо перейти в редактирование объявления
      .create-textarea
        textarea.create-textarea__item(
          placeholder='Условия объявления',
          v-model='form.conditions',
          maxlength="1000",
        )
        p.create-textarea__counter {{ conditionsCounter }}
    .create-line
      .create-line__button-red(
        :class="{'create-line__button-red--hidden': isEdit === false}",
        @click="deleteAd",
      ) Удалить объявление
      .create-line__button-green.create-line__button-green--width(
        v-if="validateFields"
        :class="{'disabled': isRateLoading}"
        @click="createAd",
      ) Далее
      el-alert.create-line__button-green.create-line__button-green--width(
        v-if="!validateFields"
        title="Проверьте правильность заполнения всех полей!",
        type="warning",
        show-icon,
        :closable="false"
      )

</template>

<script>
import utilsMixin from '../mixins/utils';
import {
  AD_FORM_DATA,
  REQUEST_BANKS,
  REQUEST_COUNTRIES,
  REQUEST_CRYPTO,
  REQUEST_CURRENCIES,
  REQUEST_PAYMENTS,
  AD_PRICE_TICKER,
  AD_DELETE,
  GET_OFFER_TIME
} from '../../store/actions/ad';
import { SET_ROUTE_DATA } from '../../store/actions/user';
import { mapGetters } from 'vuex';
import cfg from '../../../config.js';
import SelectBoxAd from './SelectBoxAd.vue';
import RequisitesAdder from './Partials/RequisitesAdder';
import AutoPrice from './Partials/AutoPrice';
import analytics from '@/components/mixins/analytics';

export default {
  components: {
    SelectBoxAd,
    RequisitesAdder,
    AutoPrice,
  },
  mixins: [utilsMixin, analytics],
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isChangeAd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeButton: {
        sale: 'Продажа',
        buy: 'Покупка',
      },
      defaultCryptoIcon: cfg.default.defaultCryptoIcon,
      defaultFiatIcon: cfg.default.defaultFiatIcon,
      defaultPaymentIcon: cfg.default.defaultPaymentIcon,
      defaultCurrencyCode: 'USD',
      extraOptions: false,
      hasMaxError: '',
      bankSelected: [],
      descriptions: [
        'Минимальный оборот пользователя в криптовалюте',
        'Минимальный размер сделки по данному объявлению',
        'Минимальный рейтинг пользователей, которым будет доступно ваше объявление. Чем выше это значение, тем меньше людей смогут его увидеть',
        'При активации, максимальный лимит будет каждый раз уменьшаться пропорционально вновь созданной сделке',
        // 'Объявление смогут видеть только доверенные пользователи',
      ],
      cryptoOptions: [],
      currencyOptions: [],
      countryOptions: [],
      paymentSystemOptions: [],
      bankOptions: [],
      showAutoPriceSettings: false,
      hasMinMaxPriceError: false,
    };
  },
  computed: {
    ...mapGetters([
      'getBalance',
      'getPriceTickers',
      'errorTemplates',
      'loading',
      'getOfferTime',
      'getUserCommissions',
    ]),

    currentBalance() {
      return this.getBalance
    },

    validateFields() {
      return !this.isLimit() && !this.hasMaxError && !this.hasMinMaxPriceError;
    },

    isZeroSellerBalance() {
      if (this.currentBalance) {
        return parseFloat(this.currentBalance.fiat.amountUsd) <= 0 || parseFloat(this.currentBalance.fiat.amount) <= 0;
      }

      return true;
    },

    zeroSellerBalanceTooltip() {
      if (this.isZeroSellerBalance) {
        return 'Недостаточно средств для создания объявления продажи.';
      }

      return '';
    },

    isLimitError() {
      return (parseFloat(this.form.min) >= parseFloat(this.form.max));
    },

    conditionsCounter() {
      return this.form.conditions ? `${this.form.conditions.length}/1000` : '0/1000';
    },

    labels() {
      return {
        crypto_currency: this.form.crypto_currency_id && this.cryptoOptions.length ? _.find(this.cryptoOptions,
          (item) => item.value === this.form.crypto_currency_id).short_title : '',
        currency: this.form.currency_id && this.currencyOptions.length ? _.find(this.currencyOptions,
          (item) => item.value === this.form.currency_id).short_title : '',
      };
    },

    showBanks() {
      if (this.paymentSystemOptions.length) {
        const paymentMethod = this.paymentSystemOptions.find(payment => payment.value === this.form.payment_system_id);
        return paymentMethod ? paymentMethod.is_bank : false;
      }
      return false;
    },

    isRateLoading() {
      return this.loading(AD_PRICE_TICKER) === 'loading'
    },
    timeOptions() {
      return this.getOfferTime.filter(item => item.is_sale === this.form.is_sale).map(item => {
        return { label: item.time.toString() + ' минут', value: item.time }
      })
    },
    bankSelectedForRequisites() {
      return this.bankSelected.map(bank => bank && bank.value ? bank.value : null)
    }
  },
  watch: {
    'form.payment_system_id'() {
      if (!this.showBanks) {
        this.form.banks = [];
        this.bankSelected = [];
      }
    },
    'bankSelected'(val) {
      this.form.banks = val.map((item) => {
        if (item) return item.value;
      });
    },
    'form.banks'(val) {
      if (val && typeof val[0] === 'object') {
        this.form.banks = this.form.banks.map((item) => {
          return item.id;
        });
      }
    },
    'form.country_id'() {
      this.loadCurrencies();
      this.loadPayments();
      this.loadBanks();
    },
    'form.currency_id'() {
      this.loadPayments();
      this.loadBanks();
    },
    'form.is_sale'(is_sale) {
      if(this.isEdit === false) {
        const { time } = this.getOfferTime.find((item) => item.is_sale === is_sale)
        this.form.time = time
      }
    },
  },
  async mounted() {
    this.loadOptionsData();
    this.priceCheck = _.debounce(this.validatePrice, 500);
    this.$store.commit(SET_ROUTE_DATA, {
      title: this.isEdit === true ? 'Отменить изменения в объявлении' : 'Отменить создание объявления',
      path: this.$route.path,
      analytics: this.isEdit === true ?
        { category: 'Ad', action: 'ad_edit_cancel' } :
        { category: 'Ad', action: 'ad_create_cancel' },
    });
    await this.$store.dispatch(GET_OFFER_TIME)
    if(!this.isEdit) {
      const { time } = this.getOfferTime.find((item) => item.is_sale === this.form.is_sale)
      this.form.time = time
    }
  },
  methods: {
    turnOnAutoPrice(status, offset, source, price) {
      this.form.auto_price.enabled = status;
      this.form.auto_price.offset = offset;
      this.form.auto_price.source = source;
      this.form.price = price;
      this.showAutoPriceSettings = false;
      this.validatePrice();
    },
    enableAutoPrice() {
      if(this.showAutoPriceSettings === false && this.form.auto_price.enabled === false && this.form.bot.enable === false) {
        this.showAutoPriceSettings = true;
      } else if (this.form.bot.enable === true) {
        this.$message({
          message: 'Для включения автоматической цены, необходимо выключить демпинг!',
          type: 'warning',
        });
      } else if (this.form.auto_price.enabled === true) {
        this.form.auto_price.enabled = false;
        this.$message({
          message: 'Вы успешно деактивировали режим автоматической цены!',
          type: 'warning',
        });
      }
    },
    enableBot() {
      if (this.form.auto_price.enabled === false && this.form.bot.enable === false && !this.hasMinMaxPriceError && !this.errors.has('price') &&
        this.form.price.toString().length >= 1) {
        this.$prompt('Пожалуйста, введите лимит цены:', 'Установка лимита демпинга', {
          confirmButtonText: 'Ок',
          cancelButtonText: 'Отмена',
          inputPattern: /^[0-9]{1,12}$/,
          inputErrorMessage: 'Пожалуйста, введите верное значение, только цифры не более 12 символов.',
          inputValidator: this.validateBotPrice,
        }).then(({ value }) => {
          this.form.bot.enable = true;
          this.form.bot.price_limit = value;
          this.$message({
            message: 'Вы успешно активировали режим демпинга!',
            type: 'success',
          });
        });
      } else if (this.form.bot.enable === true) {
        this.form.bot.enable = false;
        this.$message({
          message: 'Вы успешно деактивировали режим демпинга!',
          type: 'warning',
        });
      } else if (this.form.auto_price.enabled === true) {
        this.$message({
          message: 'Необходимо отключить автоматическое выставление цены!',
          type: 'error',
        });
      } else {
        this.$message({
          message: 'Для установки режима демпинга введите корректную цену!',
          type: 'error',
        });
      }
    },

    changeType(isSale) {
      if (this.isZeroSellerBalance && isSale === true) {
        return;
      }

      this.form.is_sale = isSale;
      this.form.bot.enable = false;
      this.form.bot.price_limit = null;
      this.form.price = '';
    },

    deleteAd() {
      if (this.isEdit === true) {
        this.$confirm('Вы уверены что хотите удалить объявление?', 'Внимание', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning',
        }).then(() => {
          this.$store.dispatch(AD_DELETE, this.form.id);
          this.$router.push({ name: 'Market' });
        });
      }
    },

    extraOptionsAction() {
      this.extraOptions = !this.extraOptions;
    },

    getTicker() {
      const { crypto_currency, currency } = this.labels;
      if (crypto_currency && currency) {
        return this.$store.dispatch(AD_PRICE_TICKER, { crypto: crypto_currency, fiat: currency });
      }
    },

    getTickerData(ticker) {
      const minPrice = ticker.min_price;
      const maxPrice = ticker.max_price;
      const basePrice = ticker.price;
      return { minPrice, maxPrice, basePrice };
    },

    validateBotPrice(botPrice) {
      const { crypto_currency, currency } = this.labels;
      const price = parseFloat(botPrice);
      if (price && crypto_currency && currency) {
        this.getTicker();
        const ticker = crypto_currency + '' + currency;

        if (this.getPriceTickers[ticker]) {
          const { minPrice, maxPrice, basePrice } = this.getTickerData(this.getPriceTickers[ticker]);
          const isSale = this.form.is_sale;
          const adPrice = parseFloat(this.form.price);
          const adPriceSale = parseFloat(this.form.price) - 1;
          const adPriceBuy = parseFloat(this.form.price) + 1;

          if (isSale === true && (price >= adPrice || price < minPrice)) {
            return this.replaceStringParams(this.errorTemplates.botPriceSale, {
              minPrice, currency, adPriceSale,
            });
          } else if (isSale === false && (price <= adPrice || price > maxPrice)) {
            return this.replaceStringParams(this.errorTemplates.botPriceBuy, {
              maxPrice, currency, adPriceBuy,
            });
          } else {
            return true;
          }
        }
      }
    },

    validatePrice() {
      const { crypto_currency, currency } = this.labels;
      const price = this.form.price;
      if (price && crypto_currency && currency) {
        this.$store.dispatch(AD_PRICE_TICKER, { crypto: crypto_currency, fiat: currency }).then(() => {
          const ticker = crypto_currency + '' + currency;
          if (this.getPriceTickers[ticker]) {
            const { minPrice, maxPrice, basePrice } = this.getTickerData(this.getPriceTickers[ticker]);

            if (price > maxPrice || price < minPrice) {
              this.hasMinMaxPriceError = this.replaceStringParams(this.errorTemplates.price, {
                minPrice, maxPrice, currency, basePrice,
              });
            } else {
              this.hasMinMaxPriceError = false;
              this.inputChange('max');
            }
          }
        });
      }
    },

    inputChange(inputType) {

      switch (inputType) {
        case 'price':
          this.priceCheck();
          break;
      }

      let staticInputs = [
        'crypto',
        'sale_type',
      ];

      if (staticInputs.indexOf(inputType) === -1 && this.form[inputType].length === 1) {
        if (this.form[inputType] === '.') {
          this.form[inputType] = '0.';
        }
      }

      let types = [
        'max',
        'price',
        'crypto',
        'sale_type',
      ];

      if (types.indexOf(inputType) !== -1) {
        let crypto_type = this.form.crypto_currency_id;
        if (crypto_type && this.form.is_sale) {
          let price = parseFloat(this.form.price);
          let max = parseFloat(this.form.max);
          let amount = parseFloat(this.getBalance.amount);
          let highLimit = (price * amount) - ((price * amount / 100) * this.getUserCommissions.total_calculated.offer_commission.btc);
          if (price && max) {
            this.hasMaxError = max > highLimit || amount === 0;
            if (this.hasMaxError) {
              this.hasMaxError = `Максимальный лимит при текущей цене: ${Math.floor(highLimit)}`;
            } else {
              this.hasMaxError = '';
            }
          }
        } else {
          this.hasMaxError = '';
        }
      }
    },
    loadOptionsData() {
      this.$store.dispatch(AD_FORM_DATA).then(() => {
        this.loadCryptoCurrencies();
        this.loadCountries();
        this.loadCurrencies();
        this.loadPayments();
        this.loadBanks();
      });
    },
    loadCryptoCurrencies() {
      this.$store.dispatch(REQUEST_CRYPTO).then(data => {
        if (data && data.length && this.isChangeAd === false) {
          this.form.crypto_currency_id = data[0].value;
        }
        this.cryptoOptions = data;
      });
    },
    loadCountries() {
      this.$store.dispatch(REQUEST_COUNTRIES).then(data => {
        if (data && data.length && this.isChangeAd === false && this.isEdit === false) {
          this.form.country_id = data[0].value;
        }
        this.countryOptions = data;
      });
    },
    loadCurrencies() {
      this.$store.dispatch(REQUEST_CURRENCIES, { country_id: this.form.country_id }).then(data => {
        if (data && data.length && this.isChangeAd === false && this.isEdit === false) {
          this.form.currency_id = data[0].value;
        }
        this.currencyOptions = data;
      });
    },
    loadPayments() {
      this.$store.dispatch(REQUEST_PAYMENTS, { country_id: this.form.country_id, currency_id: this.form.currency_id }).
      then(data => {
        if (data && data.length && this.isChangeAd === false && this.isEdit === false) {
          this.form.payment_system_id = data[0].value;
        }
        this.paymentSystemOptions = data;
      });
    },
    loadBanks() {
      this.$store.dispatch(REQUEST_BANKS, { country_id: this.form.country_id, currency_id: this.form.currency_id }).
      then(data => {
        this.bankOptions = data;
        if (this.form.banks && this.form.banks.length > 0 && this.isEdit === false) {
          this.bankSelected = this.form.banks.map(bank => this.bankOptions.filter(item => item.value === bank).pop());
        } else if (this.form.banks && this.form.banks.length > 0 && this.isEdit === true) {
          this.bankSelected = this.form.banks.map(bank => this.bankOptions.filter(item => {
            if (bank.id) {
              return item.value === bank.id;
            } else {
              return item.value === bank;
            }
          }).pop());
        }
      });
    },
    isLimit() {
      return (parseFloat(this.form.min) >= parseFloat(this.form.max));
    },
    createAd() {
      if (this.isRateLoading) return;
      if (!this.form.crypto_currency_id || !this.form.country_id || !this.form.currency_id ||
        !this.form.payment_system_id) {
        this.$message.error('Невозможно создать объявление, указаны не все данные.');
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (result && this.validateFields) {
          this.$emit('change', true);
          this.$emit('next', this.form);
          if (this.isEdit === false) {
            this.sendAnalytics('Ad', this.form.is_sale ? 'ad_create_sell_step1' : 'ad_create_buy_step1', false);
            this.$router.push({ name: 'AdCreate', params: { step: 'confirm' } });
          } else {
            this.$router.push({ name: 'AdEdit', params: { step: 'confirm' } });
          }
        } else {

        }
      });
    }
  },
};
</script>

<style lang="scss">
.create-line {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .create-line__item {
    width: 100%;
    padding-right: 10px;

    .select-box {
      width: 100%;

      .select-box__current {
        width: 100%;

        .select-box__value {
          display: flex;
          justify-content: space-between;

          img {
            display: block;
          }
        }
      }
    }
  }

  .create-input {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    height: 40px;
    color: $gray600;
    width: 100%;
    border: 1px solid transparent;

    &:hover {
      border-color: $blue200;
    }

    &:has(input:focus) {
      border-color: $blue200;
      background-color: $white;
    }

    &__item {
      height: 38px;
      border: none;

      &:focus {
        border-color: transparent;
        outline: none;
      }
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 38px;
      color: $gray600;
    }
  }
}

.create-line__item-select--text {
  width: 100%;
}

.create-extra-options__link {
  height: 40px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: $gray600 !important;
  border-radius: 10px;
  padding: 0 12px 0 16px;
  border: 1px solid transparent;
  background-color: $gray50;

  &:before {
    content: '';
    background: url(/img/arrow-down-gray.svg) no-repeat center;
    background-size: 100% auto;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    transition: 0.3s;
  }

  &:hover {
    border-color: $blue200;
  }

  &.active {
    background-color: $white;
    border-color: $blue200;
  }
}
</style>
