<template lang="pug">
  .requisites-input-main__container(:class="{'requisites-input-edit__container': isEdit}")
    .input-edit__title(v-if="isEdit") Изменение реквизита
    .create-requsites-input
      .requisite__input(
        v-loading='isLoading',
        element-loading-text='Проверка реквизита',
      )
        the-mask.create-requsites-input__item(
          v-if="currentType === getAllRequisiteTypes.PHONE",
          :placeholder="phoneMask",
          :mask="phoneMask",
          :masked="true",
          :class="{error: requisiteError}",
          v-model="requisite",
        )
        the-mask.create-requsites-input__item(
          v-if="currentType === getAllRequisiteTypes.CARD",
          :mask="cardMask",
          :masked="true",
          :class="{error: requisiteError}",
          v-model="requisite",
        )
        .requisites-textarea__container(v-if="currentType === getAllRequisiteTypes.OTHER")
          textarea.requisites-textarea__input(
          resize="none",
          v-model="requisite",
          rows="1",
          placeholder='Напишите сообщение',
          maxlength='255',
          :class="{error: requisiteError}",
        )
          .requisites-textarea__length {{ messageLength }}
        .requisites__banks(v-if="hasBanks && !isEdit")
          el-select(
            v-model.number="bank_id"
            placeholder="Выберите банк"
            @change="changeBank"
          )
            template(v-for="bank in banks")
              el-option(
                :label="bank.label"
                :value="bank.value"
              )
        el-input(
          size="large"
          placeholder='Лимит реквизита',
          v-model="max",
          class="limit-input"
          type="number"
          :class="{'limit-input-margin': hasBanks || currentType !== getAllRequisiteTypes.OTHER}",
        )
      .requsites-create-buttons(v-if="isEdit")
        i.el-icon-circle-check.control-button(:class="{disabled: requisiteError || isInactive || parseInt(max) === 0}" @click="updateRequisite()")
        i.el-icon-circle-close.control-button(@click="cancelEdit()")
      .requsites-create-buttons(v-else)
        i.el-icon-success.control-button(:class="{disabled: requisiteError || isInactive || parseInt(max) === 0}" @click="addRequisite()")
        i.el-icon-error.control-button(@click="resetRequisite(true)")
    .requisites-error(v-if="requisiteError") {{ requisiteErrorReason }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import { REQUISITE_VALIDATE } from '@/store/actions/ad';

  export default {
    props: {
      country_id: {
        type: Number,
        default: 1,
      },
      req_bank_id: {
        type: Number,
        default: 1,
      },
      selectedType: {
        type: Object,
        default() {
          return {};
        }
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      editRequisite: {
        type: String,
        default: '',
      },
      limit: {
        type: Number,
        default: 0,
      },
      editRequisiteIndex: {
        type: Number,
        default: null,
      },
      requisiteId: {
        type: Number,
        default: null
      },
      ad_banks: {
        type: Array,
        default() {
          return [];
        },
      },
      bank_options: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        requisite: '',
        max: null,
        requisiteError: false,
        isInactive: true,
        requisiteErrorReason: '',
        bank_id: null,
        bank_changed: false,
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'getUserWalletAddress',
        'getAllRequisiteTypes',
      ]),
      isLoading() {
        return this.loading(REQUISITE_VALIDATE) === 'loading';
      },
      currentType() {
        return this.selectedType.alias;
      },
      phoneMask() {
        const masks = { // TODO: Move to config if necessary
          1: ['+7 (###) ###-##-##'],
          4: ['+380 (##) ###-##-##'],
          5: ['+375 (##) ###-##-##'],
        };
        return masks[this.country_id];
      },
      cardMask() {
        return ['#### #### #### ####', '#### #### #### #### #', '#### #### #### #### ##', '#### #### #### #### ###'];
      },
      messageLength() {
        return `${this.requisite.length} / 255`
      },
      formattedRequisite() {
        if (this.currentType === this.getAllRequisiteTypes.OTHER) {
          return this.requisite;
        } else {
          let requisite = this.requisite;
          requisite = requisite.split('_').join('');
          requisite = requisite.split('-').join('');
          requisite = requisite.split(' ').join('');
          requisite = requisite.split('(').join('');
          requisite = requisite.split(')').join('');
          requisite = requisite.split('+').join('');
          requisite = requisite.trim();
          return requisite;
        }
      },
      banks() {
        return this.bank_options.filter((bank) => this.ad_banks.includes(bank.value));
      },
      hasBanks() {
        return this.ad_banks.length > 0;
      }
    },
    watch: {
      requisite(val) {
        this.isInactive = true;
        if (val.length === 0) {
          return;
        }
        this.debouncedRequisitesCheck(val)
      },
      selectedType() {
        this.resetRequisite();
      },
      country_id() {
        if (this.currentType === this.getAllRequisiteTypes.PHONE) {
          this.resetRequisite();
        }
      },
      ad_banks(nw) {
        if(nw.length > 0 && !nw.includes(this.bank_id)) {
          this.bank_id = nw[0];
        }
      }
    },
    created() {
      this.debouncedRequisitesCheck = _.debounce(this.requisiteCheck, 500);
    },
    mounted() {
      if(this.ad_banks.length > 0) {
        this.bank_id = this.ad_banks[0]
      }
      if (this.isEdit) {
        this.requisite = this.editRequisite;
        this.max = this.limit;
        if (this.req_bank_id) {
          this.bank_id = this.req_bank_id
        }
      }
    },
    methods: {
      async requisiteCheck(requisite) {
        if (requisite === this.editRequisite) {
          this.isInactive = false;
          return;
        }
        const validate = await this.$store.dispatch(REQUISITE_VALIDATE, {
          alias: this.selectedType.alias, requisite: this.formattedRequisite
        });
        this.isInactive = false;
        if (validate && validate.is_valid) {
          this.requisite = this.formattedRequisite
          this.requisiteError = false;
        } else if (validate) {
          this.requisiteError = true;
          this.requisiteErrorReason = validate.error;
        }
      },
      addRequisite() {
        if (this.isInactive || this.requisiteError) {
          return;
        } else if (this.requisite !== this.editRequisite) {
          let requisiteItem = {
            type: this.selectedType.alias,
            requisite: this.requisite,
            max: this.max,
            auto_select: false,
          };
          if(this.hasBanks) {
            requisiteItem.bank_id = this.bank_id
          }
          this.$emit('addRequisite', requisiteItem);
        }
        this.resetRequisite(true);
      },
      resetRequisite(closeAdd) {
        if (closeAdd) {
          this.$emit('closeAddRequisites');
        }
        this.requisite = '';
        this.requisiteError = false;
        this.requisiteErrorReason = '';
      },
      updateRequisite() {
        if (this.requisite === this.editRequisite && this.max === this.limit && !this.bank_changed) {
          this.cancelEdit();
        } else if (!this.isInactive && !this.requisiteError) {
          let obj = { index: this.editRequisiteIndex, requisite: this.requisite, max: this.max }
          if (this.hasBanks) {
            obj.bank_id = this.bank_id
          }
          this.$emit('updateRequisite', obj);
        }
      },
      cancelEdit() {
        this.$emit('closeEditDialog', this.editRequisiteIndex);
      },
      changeBank() {
        this.bank_changed = true;
      }
    }
  }
</script>

<style lang="scss">
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .limit-input{
    width: 100%;
    input {
      border-radius: 6px;
      background-color: $blue100;
      border: 1px solid $blue300;
      color: #000;
    }
    ::placeholder{
      color: #707070;
    }
  }
</style>
<style lang="scss" scoped>

  .requisites-input-main__container {
    width: 100%;

    &.requisites-input-edit__container {
      padding: 10px 0 0;

      .input-edit__title {
        font-size: 14px;
        padding: 5px;
      }
    }

    .create-requsites-input {
      margin-bottom: 15px;

      .requisite__input {
        width: 100%;

        .create-requsites-input__item {
          background: $blue100;
          border: 1px solid $blue300;
          height: 50px;
          border-radius: 6px;
          width: 100%;
          padding-left: 20px;
          padding-right: 10px;
          color: $blue600;
          outline: none;

          &.error {
            border: 1px solid red;
            box-shadow: 1px 1px 5px #f54545a6;
          }
        }

        .requisites-textarea__container {
          width: 100%;

          .requisites-textarea__input:focus-visible {
            outline: none;
          }
          .requisites-textarea__input {
            background: $blue100;
            border: 1px solid $blue300;
            width: 100%;
            padding: 5px;
            border-radius: 6px;
            min-height: 80px;
            max-height: 200px;

            &.error {
              border: 1px solid red;
              box-shadow: 1px 1px 5px #f54545a6;
            }
          }

          .requisites-textarea__length {
            text-align: right;
            font-size: 12px;
            color: #929292;
          }
        }
      }

      .requsites-create-buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        .control-button {
          font-size: 24px;
          padding: 0 2.5px;

          &:hover {
            cursor: pointer;
            transition:  0.3s;
          }
        }

        .el-icon-success {
          color: $blue600;

          &.disabled {
            color: #797979;
            pointer-events: none;

            &:hover {
              color: #6a6a6a;
            }
          }

          &:hover {
            color: $blue800;
          }
        }

        .el-icon-circle-check {
          color: #2464EB;

          &.disabled {
            color: #797979;
            pointer-events: none;

            &:hover {
              color: #6a6a6a;
            }
          }

          &:hover {
            color: #3BB069;
          }
        }

        .el-icon-error {
          color: #FF2D55;

          &:hover {
            color: #e03959;
          }
        }

        .el-icon-circle-close {
          color: #546aff;

          &:hover {
            color: #4b55ab;
          }
        }
      }
    }

    .requisites-error {
      padding: 5px;
      font-size: 12px;
      color: red;
    }
    .requisites__banks {
      padding-top: 20px;
    }
  }
  .limit-input-margin{
    margin: 20px 0 0;
  }

  .el-select {
    display: block;
    border-radius: 6px;
  }

</style>
