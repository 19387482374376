<template lang="pug">
  .block-range
    .block-range__text
      p {{title}}
      h4 {{amount}}%
    input.custom-range(
      type='range',
      :value="value",
      @input="$emit('input', $event.target.value)"
      :min="min",
      :max="max",
      :step="step",
    )
</template>

<script>
export default {
  name: 'InputRangePartner',
  props: {
    title: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    unit: {
      type: String,
      default: '%',
    },
    value: {
      type: Number,
      default: 0
    },
  },
  computed: {
    amount() {
      return `${this.value}`;
    },
  },
};
</script>

<style scoped>
  .block-range__text h4 {
    margin: 0;
  }

  .custom-range {
    cursor: pointer;
  }

  p {
      margin: 0;
  }
</style>