<template lang="pug">
  .create-offer--step
    .create-offer__content
      .create-line.create-line--center
        .done-offer
          h3.done-offer__title {{ isEdit === false ? 'Объявление успешно добавлено' : 'Объявление успешно отредактировано' }}
          img.done-offer__icon(src='/img/done.svg', alt='done')
          .create-line__button-green(@click="goToAds") Перейти к объявлениям
          .btn.btn__ghost_light(@click="createNew") {{ isEdit === false ? 'Создать еще одно' : 'Создать объявление' }}
</template>


<script>
  import analytics from '@/components/mixins/analytics';
  import {SET_ROUTE_DATA} from "@/store/actions/user";

  export default {
    mixins: [analytics],
    props: {
      form: {
        type: Object,
        default() {
          return {};
        }
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      createNew() {
        if (this.isEdit === false) {
          this.$emit('next');
        }
        this.$router.push({name: 'AdCreate', params: {step: 'insert'} });
      },
      goToAds() {
        this.$router.push({name: 'Market'});
      },
    },
    created() {
      this.$store.commit(SET_ROUTE_DATA, {
        title: 'К объявлениям',
        path: this.$route.path,
        backRouteName: 'MarketMy',
      });
    }
  }
</script>

<style lang="scss" scoped>

</style>
