<template lang="pug">
  .create-offer--step
    .create-offer__confirm
      .create-offer__confirm-item(v-for="fields in columns")
        ul.offer-confirm
          li.confirm-item(v-for="field in fields")
            span.confirm-item__head {{ field.title }}
            span.confirm-item__info {{ field.value }}
    .create-line.create-line--center
      .create-line__button-green(v-if="!confirmation" @click="confirm") Подтвердить
      .create-line__button-disabled(v-else) Подтвердить
    .create-line.create-line--center
      .terms-accept
        input#check.check(type='checkbox', name='check', v-model="termsAccepted")
        label.check-label(for='check')
          | Я знаком с
          span.conditions-offer(@click="conditionsWindowOpen = true")
            |  условиями создания объявления
    el-dialog.standard-dialog(
      title="Условия создания объявления"
      :visible.sync="conditionsWindowOpen"
    )
      p Пожалуйста, прочитайте правила сервиса перед созданием объявления:
      ol(v-loading="isConditionsRequirementsLoading")
        li(v-for="item in orderingCond") {{ item.text }}
      .dialog-footer(slot="footer")
        el-button(type="primary" @click="termsAccepted = true; conditionsWindowOpen = false") Ознакомлен
</template>

<script>
  import utilsMixin from '../mixins/utils';
  import {
    AD_EDIT,
    AD_CREATE,
    AD_FORM_DATA,
    REQUEST_CRYPTO,
    REQUEST_CURRENCIES,
    REQUEST_COUNTRIES,
    REQUEST_PAYMENTS
  } from '../../store/actions/ad';
  import { SET_ROUTE_DATA } from '../../store/actions/user';
  import { GET_GLOBAL_COMMISSIONS } from '../../store/actions/commission';
  import {mapGetters} from 'vuex';
  import analytics from '@/components/mixins/analytics';

  export default {
    mixins: [utilsMixin, analytics],
    props: {
      form: {
        type: Object,
        default() {
          return {};
        }
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        timeOptions: [ // TODO: global state
          {'label': '30 минут', 'value': 30},
          {'label': '45 минут', 'value': 45},
          {'label': '60 минут', 'value': 60},
          {'label': '90 минут', 'value': 90},
        ],
        cryptoOptions: [],
        countryOptions: [],
        currencyOptions: [],
        paymentSystemOptions: [],
        termsAccepted: false,
        conditionsWindowOpen: false,
        confirmation: false,
      }
    },
    computed: {
      ...mapGetters([
        'getOptions',
        'getAllRequisiteTypes',
        'getGlobalCommissions',
        'loading',
      ]),
      isConditionsRequirementsLoading() {
        return (this.loading(GET_GLOBAL_COMMISSIONS) === 'loading'
          || this.loading(REQUEST_CURRENCIES) === 'loading');
      },
      labels() {
        return {
          crypto_currency_id: _.find(this.cryptoOptions, (item) => item.value === this.form.crypto_currency_id),
          currency_id: _.find(this.currencyOptions, (item) => item.value === this.form.currency_id),
          country_id: _.find(this.countryOptions, (item) => item.value === this.form.country_id),
          payment_system_id: _.find(this.paymentSystemOptions, (item) => item.value === this.form.payment_system_id ),
          time: _.find(this.timeOptions, (item) => item.value === this.form.time),
        };
      },
      columns() {
        const currency_title = this.labels.currency_id ? this.labels.currency_id.short_title : '';
        return [ // TODO: валюта в отображении
          [
            {title: 'Тип сделки:', value: this.form.is_sale ? 'Продать' : 'Купить'},
            {
              title: 'Криптовалюта:',
              value: this.labels.crypto_currency_id ? this.labels.crypto_currency_id.label : 'Не выбрана'
            },
            {title: 'Валюта:', value: this.labels.currency_id ? this.labels.currency_id.label : 'Не выбрана'},
            {title: 'Страна:', value: this.labels.country_id ? this.labels.country_id.label : 'Не выбрана'},
            {
              title: 'Способ оплаты:',
              value: this.labels.payment_system_id ? this.labels.payment_system_id.label : 'Не выбрана'
            },
            {title: 'Окно оплаты:', value: this.validData.time ? `${this.validData.time} минут` : 'Не выбрано'},
            {title: 'Цена:', value: `${this.thousandSeparator(this.form.price)} ${currency_title}`},
            {title: 'Минимальный лимит:', value: `${this.thousandSeparator(this.form.min)} ${currency_title}`},
            {title: 'Максимальный лимит:', value: `${this.thousandSeparator(this.form.max)} ${currency_title}`},
          ],
          [
            {title: 'Минимальный оборот:', value: this.form.turnover ? this.thousandSeparator(this.form.turnover) : '0'},
            {title: 'Минимальный рейтинг:', value: this.form.review_rate ? `${this.form.review_rate}%` : '0%'},
            {title: 'Минимальное количество завершенных сделок:', value: this.form.min_deal_finished_count ? `${this.form.min_deal_finished_count} шт` : '0 шт'},
            {title: 'Процент отмененных сделок:', value: this.form.deal_cancellation_max_percent ? `${this.form.deal_cancellation_max_percent}%`: '0%'},
            {title: 'Ликвидность:', value: this.form.liquidity_required ? 'Да' : 'Нет'},
            // {title: 'Доверенный пользователи:', value: this.form.trust_required ? 'Да' : 'Нет'},
            {title: 'Подтвержденный E-mail:', value: this.form.email_confirm_required ? 'Да' : 'Нет'},
            // {title: 'Подтвержденный номер:', value: this.form.phone_confirm_required ? 'Да' : 'Нет'},
            {title: 'Не осуществлять операции с TOR-пользователями:', value: this.form.tor_denied ? 'Да' : 'Нет'},
            {title: 'Условия объявления:', value: this.form.conditions ? this.form.conditions : 'Не указаны'},
          ]
        ];
      },
      currentCurrency() {
        return this.currencyOptions.find(({ value: id }) => id === this.form.currency_id) || {};
      },
      orderingCond() {
        return [{
          text: `Стандартная торговая комиссия составляет ${this.getGlobalCommissions.global.client_commission}% от суммы сделки для откликающегося на объявление,
            но не менее минимальной суммы: ${this.getGlobalCommissions.min_fiat[this.currentCurrency.code]} ${this.currentCurrency.code}.
            Комиссия автора объявления составляет ${this.getGlobalCommissions.global.offer_commission}% без минимальной суммы.
            Также могут действовать индивидуальные и специальные комиссии.`,
        }, {
          text: 'Предложение клиенту провести сделку за рамками площадки Finex может привести к ограничениям вашего аккаунта.',
        }, {
          text: 'Комиссии, связанные с пересылкой средств, должны быть уже включены в курс объявления или быть четко прописаны в условиях.',
        }, {
          text: 'Попытки обмана или сокрытия фактов во время сделки или рассмотрения спорной ситуации могут привести к наложению перманентных ограничений на ваш аккаунт.',
        }];
      },
      validData() {
        return {
          id: this.form.id,
          banks: this.form.banks || [],
          is_sale: this.form.is_sale,
          payment_system_id: this.form.payment_system_id || null,
          requisites: this.formattedRequisites || [],
          country_id: this.form.country_id || null,
          currency_id: this.form.currency_id || null,
          crypto_currency_id: this.form.crypto_currency_id || null,
          conditions: this.form.conditions || '',
          price: parseFloat(this.form.price),
          min: parseFloat(this.form.min),
          max: parseFloat(this.form.max),
          userquility: parseInt(this.form.userquility),
          turnover: parseFloat(this.form.turnover) || 0,
          min_deal_finished_count: parseInt(this.form.min_deal_finished_count) || 0,
          time: parseInt(this.form.time),
          review_rate: parseInt(this.form.review_rate) || 0,
          is_active: this.form.is_active,
          liquidity_required: this.form.liquidity_required,
          email_confirm_required: this.form.email_confirm_required,
          phone_confirm_required: this.form.phone_confirm_required,
          trust_required: this.form.trust_required,
          tor_denied: this.form.tor_denied,
          deal_cancellation_max_percent: parseInt(this.form.deal_cancellation_max_percent) || 99,
          bot_enable: this.form.bot.enable,
          bot_price_limit: this.form.bot.price_limit,
          auto_price_enable: this.form.auto_price.enabled,
          auto_price_source: this.form.auto_price.source,
          auto_price_offset: parseFloat(this.form.auto_price.offset),
        }
      },
      formattedRequisites() {
        const result = [];
        if (this.form.requisites) {
          this.form.requisites.forEach((requisite) => {
            if (requisite.type !== this.getAllRequisiteTypes.OTHER) {
              let currentRequisite = requisite.requisite;
              currentRequisite = currentRequisite.split('_').join('');
              currentRequisite = currentRequisite.split('-').join('');
              currentRequisite = currentRequisite.split(' ').join('');
              currentRequisite = currentRequisite.split('(').join('');
              currentRequisite = currentRequisite.split(')').join('');
              requisite.requisite = currentRequisite.trim();
            }
            result.push({
              type: requisite.type,
              requisite: requisite.requisite,
              auto_select: requisite.auto_select,
              max: requisite.max,
              priority: requisite.priority
            });
          });
          return result;
        }

        return [];
      }
    },
    mounted() {
      this.$store.dispatch(AD_FORM_DATA);
      this.$store.dispatch(GET_GLOBAL_COMMISSIONS);
      this.loadOptionsData();
      this.$store.commit(SET_ROUTE_DATA, {
        title: this.isEdit === true ? 'Редактировать объявление' : 'Редактировать объявление',
        path: this.$route.path,
      });
    },

    methods: {
      loadOptionsData() {
        this.$store.dispatch(AD_FORM_DATA).then(data => {
          this.cryptoOptions = data[REQUEST_CRYPTO] || [];
          this.countryOptions = data[REQUEST_COUNTRIES] || [];
          this.loadCurrencies();
          this.loadPayments();
        });
      },
      loadCurrencies() {
        this.$store.dispatch(REQUEST_CURRENCIES)
          .then(data => {
            this.currencyOptions = data || [];
          });
      },
      loadPayments() {
        this.$store.dispatch(REQUEST_PAYMENTS, {currency_id: this.form.currency_id})
          .then(data => this.paymentSystemOptions = data || []);
      },
      confirm() {
        if (this.termsAccepted) {
          this.confirmation = true;
          let data = {};
          if (this.isEdit) {
            data.id = this.form.id;
            data.data = this.validData;
          } else {
            data = this.validData;
          }
          this.$store.dispatch(this.isEdit ? AD_EDIT : AD_CREATE, data)
            .then((response) => {
              this.$emit('confirm', response.data);
              if(this.isEdit === false) {
                this.$router.push({name: 'AdCreate', params: {step: 'done'} });
              } else {
                this.$router.push({name: 'AdEdit', params: {step: 'done'} });
              }
            })
            .catch((data) => {
              let content = [];
              _.each(data.errors, (message) => {
                if(_.isArray(message)){
                  content.push(message.join("<br>"));
                } else {
                  content.push(message);
                }
              });
              this.$alert(_.uniq(content).join("<br>"), 'Ошибка', { dangerouslyUseHTMLString: true});
            })
            .finally((data) => {
              this.confirmation = false;
            });
          if (!this.isEdit) {
            const action = this.form.is_sale ? 'ad_create_sell_step2' : 'ad_create_buy_step2';
            this.sendAnalytics ('Ad', action, false);
            this.sendAnalytics ('Ad', 'ad_create_success', false, ['yandex']);
            this.sendAnalytics ('Ad', 'AddPaymentInfo', false, ['facebook']);
          }
        } else {
          this.$message({
            message: 'Необходимо принять условия создания объявления',
            type: 'error'
          });
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

  .create-offer__confirm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;

    &-item {
      width: 48%;
    }
  }

  .offer-confirm {
    font-family: 'Inter' sans-serif;
    padding: 0;

    &__button {
      width: 154px;
      height: 36px;
      margin-left: 50px;
      margin-top: 60px;
    }
  }

  .offer-confirm-conditions {
    padding-top: 12px;
    font-family: 'Inter' sans-serif;

    &__head {
      color: #555;
    }

    &__text {
      padding-top: 20px;
      color: #D8D8D8;
    }
  }

  .confirm-item {
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    justify-content: space-between;
    line-height: 18px;
    align-items: center;
    position: relative;

    &__head {
      display: flex;
      width: 370px;
      color: #555;
    }

    &__info {
      display: flex;
      color: #555;
      font-weight: bold;
      text-align: end;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #F3F2F5;
    }

    &:last-child:after {
      height: 0;
    }

  }

  .conditions-offer {
    color: $blue600;
    cursor: pointer;
  }
  .confirm-item__info {
    word-break: break-word;
  }
</style>
