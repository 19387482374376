<template lang="pug">
.deals-limits
  el-button(type="primary" size="small" @click="() => isDialog = true") Управление лимитами по сделкам
  el-dialog(:visible.sync="isDialog" title="Управление лимитами по сделкам" custom-class="fit-content" )
    .limits-row
      .limits-row__input Количество сделок
      .limits-row__input Количество минут
    .limits-row(v-for="limit in requisiteLimits" :key="limit.id")
      el-input.limits-row__input(size="small" type="number" placeholder="0" :disabled="isUpdating" v-model.number="limit.limit")
        template(v-slot:suffix)
          el-badge(:value="`${limit.left}/${limit.readonlyLimit}`" type="danger")
      el-input.limits-row__input(size="small" type="number" placeholder="0" :disabled="isUpdating" v-model.number="limit.timeout_minutes")
      .limits-row__actions
        el-button.primary(
          :disabled="isUpdating || isLoading.update"
          plain size="small" @click="() => updateLimit(limit)"
        ) Обновить
        el-button(plain size="small" type="danger" :disabled="isLoading.delete || isUpdating" @click="() => deleteLimit(limit.id)") Удалить
        el-button(plain size="small" type="warning" :disabled="isLoading.reset || isUpdating" @click="() => resetLimit(limit.id)") Сбросить
    .limits-row(v-if="isNewLimitRow")
      el-input.limits-row__input(
        size="small" type="number" placeholder="0" :disabled="isUpdating"
        v-model.number="newLimit.deals" @keyup.enter.native="addNewLimit"
      )
      el-input.limits-row__input(
        size="small" type="number" placeholder="0" :disabled="isUpdating"
        v-model.number="newLimit.minutes" @keyup.enter.native="addNewLimit"
      )
      el-button(
        :disabled="!isNewLimitComplete || isLoading.add || isUpdating"
        plain size="small" type="success" @click="addNewLimit"
      ) Добавить
</template>

<script>
import {
  REQUISITE_DEALS_LIMITS_ADD,
  REQUISITE_DEALS_LIMITS_UPDATE,
  REQUISITE_DEALS_LIMITS_DELETE,
  REQUISITE_DEALS_LIMITS_RESET
} from "../../../store/actions/ad";

export default {
  name: "DealsLimit",

  emits: ['update:requisite'],

  props: {
    isUpdating: {
      type: Boolean,
      required: true,
    },
    requisite: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: {
        add: false,
        delete: false,
        reset: false,
        update: false,
      },
      isDialog: false,
      newLimit: {
        id: Math.random(),
        deals: null,
        minutes: null,
      },
      requisiteLimits: [],
    };
  },

  computed: {
    isNewLimitComplete() {
      return this.newLimit.deals && this.newLimit.minutes;
    },
    isNewLimitRow() {
      return this.requisiteLimits.length < 4;
    },
  },

  mounted() { this.setRequisiteLimits() },
  watch: {
    requisite: { handler() { this.setRequisiteLimits() }, deep: true },
  },

  methods: {
    async addNewLimit() {
      this.isLoading.add = true;
      try {
        const res = this.isNewLimitComplete ? await this.$store.dispatch(REQUISITE_DEALS_LIMITS_ADD, {
          requisite_id: this.requisite.id,
          limit: this.newLimit.deals,
          timeout_minutes: this.newLimit.minutes,
        }) : null
        res && (this.newLimit = {id: Math.random(), deals: null, minutes: null})
        && this.$message.success('Лимит успешно добавлен')
        && this.$emit('update:requisite', this.requisite)
      } catch (e) { console.error(e) }
      this.isLoading.add = false;
    },

    async deleteLimit(id) {
      this.isLoading.delete = true;
      try {
        await this.$confirm('Вы действительно хотите удалить лимит?', 'Внимание!', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Не удалять',
          confirmButtonClass: 'button-bg--red',
          type: 'warning'
        })
        const res = id ?
          await this.$store.dispatch(REQUISITE_DEALS_LIMITS_DELETE, {id})
          : null
        res && this.$emit('update:requisite', this.requisite)
        && this.$message.success('Лимит успешно удален')
      } catch (e) { console.error(e) }
      this.isLoading.delete = false;
    },

    async resetLimit(id) {
      this.isLoading.reset = true;
      try {
        await this.$confirm('Вы действительно хотите сбросить лимит?', 'Внимание!', {
          confirmButtonText: 'Сбросить',
          cancelButtonText: 'Не сбрасывать',
          confirmButtonClass: 'button-bg--red',
          type: 'warning'
        })
        const res = id ?
          await this.$store.dispatch(REQUISITE_DEALS_LIMITS_RESET, {id})
          : null
        res && this.$emit('update:requisite', this.requisite)
        && this.$message.success('Лимит успешно сброшен')
      } catch (e) { console.error(e) }
      this.isLoading.reset = false;
    },

    async updateLimit(limit) {
      this.isLoading.update = true;
      try {
        const res = await this.$store.dispatch(REQUISITE_DEALS_LIMITS_UPDATE, {
          limit: limit.limit,
          timeout_minutes: limit.timeout_minutes,
          id: limit.id,
        })
        res && this.$emit('update:requisite', this.requisite)
        res && this.$message.success('Лимит успешно обновлен');
      } catch (e) { console.error(e) }
      this.isLoading.update = false;
    },

    setRequisiteLimits() {
      this.requisiteLimits = Array.isArray(this.requisite.deals_limits) ? this.requisite.deals_limits
          .map(limit => ({...limit, readonlyLimit: limit.limit}))
          .sort((a, b) => a.id - b.id)
        : [];
    }
  }
}
</script>

<style lang="scss" scoped>
.deals-limits {
  margin-bottom: 10px;
}

.limits-row {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
  &__input {
    width: 130px;
    font-size: 12px;
  }
  &__actions {
    display: flex;
    gap: 5px;
  }
}
.limits-title {
  font-size: 14px;
  color: #8C8C8C;
}
span.input-label {
  font-size: 10px;
  color: #8C8C8C;
  line-height: 10px;
}

.el-button {
  border-radius: 10px;
  &.primary {
    color: #2464EB;
    &:hover {
      color: white;
      background-color: #2464EB;
    }
  }
}
</style>
